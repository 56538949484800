import logo from '../joy-logo.svg';
import { Link } from 'react-router-dom';

function Header({ title }) {
    return (
        <header className="divide-solid flex mx-auto lg:w-3/6 mb-10 flex lg:flex-row sm:flex-col">
            <img alt='The Ananda Joy Symbol' src={logo} className="pt-8" />
            <h2 className="pt-8 text-6xl text-white text-center">{title}</h2>
            <div>
                <Link to="/talk-of-the-month">Talk of the Month</Link>
            </div>
        </header>
    );
}

export default Header;

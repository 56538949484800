const Loading = () => {
  return (
    <>
      <div className="fixed top-0 right-0 h-screen w-screen z-50 flex flex-col justify-center items-center space-y-6">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        <div className="block clear-both">
          <p className="my-20 text-2xl">Thank you for your patience while we load the beta version of the Treasures Library. 📁</p>
        </div>
      </div>
    </>
  );
};
export default Loading;

import { useEffect, useState } from "react";
import ProductList from "./components/ProductList";
import ProductTotal from "./components/ProductTotal";
import Header from "./components/Header";
import WoocommerceRestAPI from "@woocommerce/woocommerce-rest-api";

const Woo = new WoocommerceRestAPI({
    url: 'https://treasuresalongthepath.com/',
    consumerKey: 'ck_2422919fce31e851c7c99dfb75c55e077a0a4a82',
    consumerSecret: 'cs_db2c4b1a1746b0ed202cf4b03304d736d49679e4',
    wpAPI: true,
    version: 'wc/v3'
});

function App({ appName }) {
    const [total, setTotal] = useState(undefined);

    useEffect(() => {
        Woo.get('reports/products/totals')
            .then(response => setTotal(response.data[2].total))
            .catch((e) => console.error("there was a problem fetching the total..."));
    }, []);

    return (
        <div className="App w-full px-5 bg-yellow-400">
            <Header title={appName} />
            <ProductTotal total={total} />
            <ProductList
                key={new Date().getTime()}
                prodTotal={total}
            />
        </div>
    );
}

export default App;

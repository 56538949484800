import { Button, Icon, Image, Modal } from 'semantic-ui-react';

const ProductInfoModal = ({ showModal, setShowModal, title, description, image, permalink }) => {
    return (
        <>
            <Modal
                className="max-w-full"
                open={showModal}
                onClose={() => setShowModal(false)}
                onOpen={() => console.log(`modal was opened...`)}
            >
                <Modal.Header>{title} <Icon className='float-right cursor-pointer' name='close' onClick={() => setShowModal(false)} /></Modal.Header>
                <Modal.Content image scrolling>
                    <Image size='huge' src={image} wrapped />
                    <Modal.Description>
                        <div dangerouslySetInnerHTML={{ __html: description }}></div>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => {
                        window.open(permalink);
                    }} primary>
                        Download Talk <Icon name='chevron right' />
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default ProductInfoModal;

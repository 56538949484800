import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-h5-audio-player/lib/styles.css';
import App from './App';
import TalkOfTheMonth from './components/TalkOfTheMonth';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const appName = 'Treasures Library';

console.log('AND WE ARE LIVE!');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App appName={appName} />} />
        <Route path="talk-of-the-month" element={<TalkOfTheMonth />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

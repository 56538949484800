import { useState, useEffect, useRef } from 'react';
import ProductCard from './ProductCard';
import Loading from "./Loading";
import Search from "./Search";
import AudioSidebar from "./AudioSidebar";
import AudioPlayer from "react-h5-audio-player";
import ProductInfoModal from "./ProductInfoModal";
import WoocommerceRestAPI from '@woocommerce/woocommerce-rest-api';
import { compressToUTF16, decompressFromUTF16 } from 'lz-string';

export default function ProductList({ prodTotal, cachePrimed, tester }) {
    const [data, setData] = useState([]);
    const [tmpData, setTmpData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [results, setResults] = useState([]);
    const [productInfoShow, setProductInfoShow] = useState(false);
    const [productPlay, setProductPlay] = useState(false);
    const [mp3URL, setMp3URL] = useState(undefined);
    const [mp3Title, setMp3Title] = useState(undefined);
    const [mp3Desc, setMp3Desc] = useState(undefined);
    const [mp3Image, setMp3Image] = useState(undefined);
    const [talkLink, setTalkLink] = useState(undefined);
    const [pageNum, setPageNum] = useState(1);
    const [rcvdCount, setRcvdCount] = useState(0);
    
    const Woo = new WoocommerceRestAPI({
        url: 'https://treasuresalongthepath.com/',
        consumerKey: 'ck_2422919fce31e851c7c99dfb75c55e077a0a4a82',
        consumerSecret: 'cs_db2c4b1a1746b0ed202cf4b03304d736d49679e4',
        wpAPI: true,
        version: 'wc/v3'
    });

    useEffect(() => {
        setLoading(true);

        if (rcvdCount < prodTotal) {
            Woo.get('products', {
                per_page: 100,
                page: pageNum
            })
                .then(response => {
                    setTmpData(response.data);
                })
                .catch((e) => console.log(e));

            setPageNum(pageNum + 1);
            setRcvdCount(rcvdCount + 100);
        }
    }, [rcvdCount]);

    useEffect(() => {
        setData(data.concat(tmpData));
    }, [tmpData]);

    useEffect(() => {
        setSearchData(data);
        setResults(data)
        setLoading(false);
    }, [data]);

    return (
        <>
            <div className="w-3/4 mx-auto">
                <Search data={searchData} updateData={setResults} />
            </div>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-0 mx-auto">

                {loading && <Loading />}
                {results.length !== 0 && results.map(({ id, name, permalink, images, downloads, description }, index) => {
                    return <>
                        <ProductCard
                            key={`${id}-${new Date().getTime()}`}
                            id={id}
                            name={name}
                            description={description}
                            image={(images[0] !== undefined) && images[0].src}
                            permalink={permalink}
                            downloads={downloads}
                            setProductInfoShow={setProductInfoShow}
                            setProductPlay={setProductPlay}
                            mp3URL={mp3URL}
                            setMp3URL={setMp3URL}
                            setMp3Title={setMp3Title}
                            setMp3Desc={setMp3Desc}
                            setMp3Image={setMp3Image}
                            setTalkLink={setTalkLink}
                        />
                    </>;
                }
                )}
                <AudioSidebar
                    key={results.id}
                    animation={"scale down"}
                    direction={"bottom"}
                    visible={productPlay}
                    setProductPlay={setProductPlay}
                    mp3URL={mp3URL}
                    title={mp3Title}
                    setMp3URL={setMp3URL}>
                    <AudioPlayer src={mp3URL} />
                </AudioSidebar>
            </div>
            <ProductInfoModal key={results.id} showModal={productInfoShow} setShowModal={setProductInfoShow} title={mp3Title} description={mp3Desc} image={mp3Image} permalink={talkLink} />
        </>
    );
}

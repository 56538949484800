export default function ProductTotal({ total }) {
    return (
        <>
            {total &&
                <div className="mx-auto w-full">
                    <h3 className="text-center"><span className="text-yellow-200 text-2xl">{total}</span> talks and more to come!</h3>
                </div>
            }
        </>
    );
}

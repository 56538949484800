import { Image } from 'semantic-ui-react';

const ProductCard = ({ id, name, image, description, permalink, downloads, setProductPlay, setProductInfoShow, mp3, setMp3URL, setMp3Title, setMp3Desc, setMp3Image, setTalkLink
}) => {
    return (
        <>
            <div key={id}>
                <div key={id} className="bg-gray-50 rounded-md w-3/4 sm:mx-auto overflow-hidden cursor-pointer my-10 shadow-xl" onClick={() => {
                    setProductInfoShow(true);
                    setMp3Title(name ? name : undefined);
                    setMp3Desc(description);
                    setMp3Image(image);
                    setTalkLink(permalink);
                }}>
                    <Image
                        key={id + new Date().getTime()}
                        className='rounded-md mx-auto w-full object-cover'
                        src={image ? image : 'https://cdn.treasuresalongthepath.com/wp-content/uploads/2013/07/swami-kriyananda-yogananda-215x215.jpg'}
                        as='a'
                        size='large'
                        target='_blank'
                    />
                    <h3 key={id} className='px-5 pb-3 text-center text-2xl'>{name}</h3>
                </div>
            </div>
        </>
    )
}

export default ProductCard;

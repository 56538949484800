import { useEffect, useState } from 'react';
import AudioSidebar from './AudioSidebar';
import WoocommerceRestAPI from '@woocommerce/woocommerce-rest-api';

const Woo = new WoocommerceRestAPI({
    url: 'https://treasuresalongthepath.com/',
    consumerKey: 'ck_2422919fce31e851c7c99dfb75c55e077a0a4a82',
    consumerSecret: 'cs_db2c4b1a1746b0ed202cf4b03304d736d49679e4',
    wpAPI: true,
    version: 'wc/v3'
});

function TalkOfTheMonth() {
    const [talks, setTalks] = useState(undefined);
    const [talkPlay, setTalkPlay] = useState(false);

    useEffect(() => {
        console.log(`Talk of the Month: loaded.`);
        Woo.get('products', {
            per_page: 100,
            category: 148
        })
            .then(response => {
                setTalks(response.data);
            });
    }, []);

    return (
        <div>
            <h2>Talk of the month</h2>
            <p>The talk of the month features a new talk every month that members can listen to.</p>
            {talks && talks.map(({ name, description, images, downloads }) => {
                return (
                    <>
                        <div className="clear-both">
                            <h2 class="">{name}</h2>
                            <img alt="Swami Kriyananda" className="w-40 float-left" src={images[0].src} />
                            <p>{description.replace(/<\/?[^>]+(>|$)/g, '')}</p>
                            <audio controls src={downloads.length ? downloads[0].file : ""} />
                        </div>
                    </>
                );
            })}
            <AudioSidebar animation={"scale down"} direction="bottom" />
        </div >
    )
}

export default TalkOfTheMonth;

import {
    Grid,
    Header,
    Segment,
    Sidebar,
    Icon,
} from 'semantic-ui-react';

const AudioSidebar = ({ title, animation, direction, visible, mp3, setProductPlay, children }) => (
    <Sidebar
        as={Segment}
        animation={animation}
        direction={direction}
        visible={visible}
    >
        <Grid textAlign='center'>
            <Grid.Row columns={1}>
                <Grid.Column>
                    <Header as='h3'>{title}</Header>
                    <Icon name='caret down' onClick={() => {
                        console.log(`Caret down clicked.`);
                        setProductPlay(false);
                    }} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                {children}
            </Grid.Row>
        </Grid>
    </Sidebar>
);

export default AudioSidebar;
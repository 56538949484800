import { useState } from 'react';
import Fuse from "fuse.js";
import { Input } from 'semantic-ui-react';

export default function Search({ data, updateData }) {
    const options = { keys: ["name", "slug", "description", "tags"] };
    const searchIndex = Fuse.createIndex(options.keys, data);
    const fuse = new Fuse(data, options, searchIndex);
    const [loading, setLoading] = useState(false);

    return (
        <>
            {loading &&
                <Input fluid className="mx-auto" loading icon="search" iconPosition="left" onChange={(e) => {
                    setLoading(true);
                    /* convert search results into mappable array of objects */
                    let fuzzyResults = fuse.search(e.target.value);
                    let arr = [];
                    fuzzyResults.map(({ item }) => { return arr.push(item); });
                    /* send results back to ProductList */
                    updateData(arr.length ? arr : data);
                    setLoading(false);
                }} />
            }
            {!loading &&
                <Input fluid size='large' className="mx-auto lg:w-3/6 mb-20" icon="search" iconPosition="left" onChange={(e) => {
                    setLoading(true);
                    /* convert search results into mappable array of objects */
                    let fuzzyResults = fuse.search(e.target.value);
                    let arr = [];
                    fuzzyResults.map(({ item }) => { return arr.push(item); });
                    /* send results back to ProductList */
                    updateData(arr.length ? arr : data);
                    setLoading(false);
                }} />
            }
        </>
    );
}
